import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  cn
} from "../lib/helpers";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BackgroundImage from 'gatsby-background-image'
import { responsiveTitle3 } from "../components/typography.module.css"
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import Video from "../components/video";
import { Figure } from "../components/figure"

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      mainImage {
        asset {
          _id
          url
          fluid {
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    home: sanityHome(_id: { regex: "/(drafts.|)home/" }) {
      intro
      _rawVideo(resolveReferences: {maxDepth: 10})
      textColor
      textEffect
      mainImage {
        asset {
          _id
          fluid {
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    projects: allSanityProjects {
      edges {
        node {
          projects {
            mainImage {
              asset {
                _id
              }
              alt
            }
            _rawExcerpt
            _rawDescription
            _rawAdditionalInfo
            location
            title
            categories {
              title
            }
            _rawPreviewVideo(resolveReferences: {maxDepth: 10})
            slug {
              current
            }
            id
          }
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const home = (data || {}).home;
  const projectNodes = data.projects ? mapEdgesToNodes(data.projects) : [];
  const seoImage = site.mainImage ? `${site.mainImage.asset.url}?w=1200&h=600` : "";
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const imageData = getGatsbyImageData(site.mainImage.asset, { maxWidth: 2000 }, clientConfig.sanity);

  console.log(projectNodes)
  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} image={seoImage} />
      <Container>
        <div className="-mx-3 md:-mx-6 -mt-6 pb-12 md:pb-24 relative bg-bg">
          <div className="aspect-w-16 relative aspect-h-16 md:aspect-h-9 overflow-hidden bg-bg opacity-70">
            {home.mainImage && <Figure mode="fill" node={home.mainImage} />}
            {home._rawVideo && <Video
              assetDocument={home._rawVideo.asset}
              autoplay={true}
              loop={true}
              muted={true}
              showControls={false}
            />}
          </div>
          <h1 style={{ color: home.textColor, mixBlendMode: home.textEffect }} className={cn(responsiveTitle3, "w-9/12 md:w-8/12 text-center absolute left-1/2 bottom-0 transform -translate-x-1/2 mb-16 md:mb-32 ")}>{home.intro}</h1>
        </div>
        {projectNodes && (
          <ProjectPreviewGrid
            title="Projects"
            nodes={projectNodes[0].projects}
          />
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
